<template>
  <div class="box">
    <div class="head">注册页</div>

    <input class="num" type="text" placeholder="请输入手机号"  v-model="phone"/>

    <div style="display:flex">
      <input class="yanzhen" type="text" placeholder="请输入验证码" v-model="phoneCode"/>
      <button class="get" :disabled="disable" @click="Dtime">{{ getCode }}</button>
    </div>

    <input class="pass" type="password" placeholder="请输入密码" v-model="password"/>

    <input class="pass" type="password" placeholder="请确认密码" style="margin-bottom:3rem"  v-model="spassword"/>

    <button @click="confirm">确定</button>
  </div>
</template>

<script>
import md5 from 'js-md5';
export default {
  data() {
    return {
      getCode: "获取验证码",
      isGeting: false,
      count: 59,
      disable: false,
      phone:'',
      phoneCode:'',
      password:'',
      spassword:'',
      memberCode:'',

    };
  },
  created(){
    var memberCode=this.$route.query.memberCode;
    this.memberCode=memberCode;
  },
  methods: {
    Dtime() {
      //发送验证码
      this.getRndCode();
      //禁用按钮
      this.disable=true;
      //读秒
      const countDown = setInterval(() => {
        if (this.count < 1) {
          this.isGeting = false;
          this.getCode = "获取验证码";
          this.count = 59;
          clearInterval(countDown);
          //启用按钮
          this.disable=false;
        } else {
          this.isGeting = true;
          this.disable = true;
          this.getCode = this.count-- + "s后重发";
        }
      }, 1000);
    },
    confirm(){
      //确定
      var that = this;
      //手机号码不能空
      if(that.phone ==''){
        this.$message("手机号码不能空");
        return;
      }
        //手机号码不能空
      if(that.phone.length !=11){
        this.$message("手机号码不能空");
        return;
      }
      //验证码不能为空
        if(that.phoneCode ==''){
        this.$message("验证码不能空");
        return;
      }
      //判断二次密码是否一致
      if(that.password !=that.spassword){
        this.$message("二次密码输入不一致");
        return;
      }
      
      this.$axios({
        url: "/member/register",
        data: {
          phone: that.phone,
          phoneCode: that.phoneCode,
          password: md5(that.password),
          recommend:that.memberCode
        },
        method: "post",
      }).then((res) => {
        console.log(res);
        if (res.code == 200) {
          this.$message("注册成功");
          this.$router.push({
              path: "/qrcode",
            });
        } else {
          this.$message(res.message);
        }
      });

    },
    getRndCode(){
       var that = this;
        //手机号码不能空
      if(that.phone ==''){
        this.$message("手机号码不能空");
        return;
      }
        //手机号码不能空
      if(that.phone.length !=11){
        this.$message("手机号码不能空");
        return;
      }
      //获取验证码
    this.$axios({
        url: "/member/getcode",
        data: {
          phone: that.phone,  
        },
        method: "post",
      }).then((res) => {
        console.log(res);
        if (res.code == 200) {
          this.$message("发送成功");
        } else {
          this.$message(res.message);
        }
      });

    }
  },
};
</script>

<style scoped>
.notClick {
  color: grey;
  cursor: not-allowed;
}
.box {
  position: fixed;
  display: flex;
  flex-direction: column;
  width: 100vw;
  margin-left: 50%;
  margin-top: 50%;
  transform: translate(-43%, -30%);
  /* padding: 7.9375rem 0 0 2.3125rem; */
}
.head {
  margin-bottom: 1.5625rem;
  font-size: 1.25rem;
  font-weight: bolder;
}
input {
  width: 67%;
  height: 30pt;
  margin-bottom: 0.9375rem;
  border-radius: 15pt;
  padding: 0 2.2125rem;
  border: 1px solid rgba(128, 128, 128, 0.4);
  font-size: 1rem;
}
.yanzhen {
  width: 27%;
  border-radius: 15pt 0 0 15pt;
  border-right: none;
  background: url("../../assets/images/shield.png") no-repeat;
  background-size: 10%;
  background-position: 0.5rem 0.6rem;
}
.num {
  background: url("../../assets/images/head.png") no-repeat;
  background-size: 6%;
  background-position: 0.3rem 0.6rem;
}
.pass {
  background: url("../../assets/images/lock.png") no-repeat;
  background-size: 6%;
  background-position: 0.4rem 0.5rem;
}
.get {
  width: 40%;
  height: 31.5pt;
  color: #38b7fd;
  background: white;
  border-radius: 0 15pt 15pt 0;
  border: 1px solid rgba(128, 128, 128, 0.4);
  border-left: none;
  line-height: 30pt;
  text-align: center;
}
button {
  width: 85%;
  height: 35pt;
  border-radius: 17pt;
  border: 1px solid;
  color: white;
  background: linear-gradient(-90deg, #4baffd, #01d1fd);
}

</style>
